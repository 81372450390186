/**
 * Product category JS
 */
jQuery( function( $ ) {
  function normalise() {
    if( $( 'body.body--tax-product_cat' ).length ) {

      $( 'ul.products > li').css('height', ''); // remove the height so we can re-measure

      if(window.innerWidth < 990) {
        return;
      }
      
      var highest = 0;
      $( 'ul.products > li' ).each( function( k, element ) {
        if( $(element).height() > highest ) {
          highest = $(element).height();
        }
      } );

      $( 'ul.products > li').height(highest);
    }
  }

  $( window ).on( 'resize', normalise );
  normalise();

} );
