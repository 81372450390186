/***
* Homepage JS
*/
jQuery(function($) {
  if($('body.body--home').length) {
    $('#supplier-carousel').owlCarousel({
      items: 4,
      margin: 48,
      loop: false,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 4
        }
      }
    });
    $('#product-carousel').owlCarousel({
      items: 4,
      margin: 48,
      loop: false,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 4
        }
      }
    });
  }
});
