/**
 * Blog normalise
 */

jQuery(function($) {
  if(!$('body.body--blog').length) {
    return;
  }

  /**
   * Normalise blog post elements when window is resized
   */
  function normalise() {
    var $elements = $('.blog-post-card-lockup__item');
	$elements.height('auto');
    var highest = [null, 0];
    $elements.each(function(index, element) {
      if(highest[1] < $(element).height()) {
        highest = [$(element), $(element).height()];
      }
    });
    $elements.height(highest[1]);
  }

  normalise();

  $(window).on('resize', normalise);
});
