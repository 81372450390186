/***
* Taste of Arran Off-canvas nav
*/

// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function ( $, window, document, undefined ) {

	"use strict";

		// undefined is used here as the undefined global variable in ECMAScript 3 is
		// mutable (ie. it can be changed by someone else). undefined isn't really being
		// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
		// can no longer be modified.

		// window and document are passed through as local variable rather than global
		// as this (slightly) quickens the resolution process and can be more efficiently
		// minified (especially when both are regularly referenced in your plugin).

		// Create the defaults once
		var pluginName = "c7offcanvas",
				defaults = {
				menu_button_selector: "#responsive-toolbar-button--menu",
        off_canvas_selector: "#off-canvas-nav",
				canvas_selector: "#off-canvas-nav__canvas",
				close_button_selector: '#off-canvas-nav__close-button'
		};

		// The actual plugin constructor
		function Plugin ( element, options ) {
				this.element = element;
				// jQuery has an extend method which merges the contents of two or
				// more objects, storing the result in the first object. The first object
				// is generally empty as we don't want to alter the default options for
				// future instances of the plugin
				this.settings = $.extend( {}, defaults, options );
				this._defaults = defaults;
				this._name = pluginName;
				this._nav_state = false; // false = invisible
				this.init();
		}

		// Avoid Plugin.prototype conflicts
		$.extend( Plugin.prototype, {
				init: function () {
						// check the menu button and off-canvas elements exis

						var erroneous = [];

						if( !$(this.settings.menu_button_selector).length ) {
								erroneous.push( 'menu_button' );
						}

						if( !$(this.settings.off_canvas_selector).length ) {
								erroneous.push( 'off_canvas' );
						}

						if( !$(this.settings.canvas_selector).length ) {
								erroneous.push( 'canvas' );
						}

						if( erroneous.length ) {
							var erroneous_elements = erroneous.join(' and ');
							throw "Could not initialise off-canvas menu ... check " + erroneous_elements + " selectors are correct.";
						}

						// inject our overlay
						this._injectOverlay();

						// bind up our elements
						this._bindElements();

						// hide off-canvas children
						$('#off-canvas-nav__menu .primary-nav__content__item--has-children > div.primary-nav__content__wrapper').hide();
				},
				_bindElements: function() {
					var that = this;
					$( this.settings.menu_button_selector ).click(function() {
						// console.log( $( this ) );
						that._setNavState( 'toggle' );
					});
					$( '#off-canvas-nav__overlay' ).click(function() {
						that._setNavState( false );
					});
					$('#off-canvas-nav__menu .primary-nav__content__item--has-children > a').click(function(e) {
						e.preventDefault();
						$(this).next().slideToggle();
					});
					$('#off-canvas-nav__close-button').click(function() {
						that._setNavState( false );
					});
				},
				_setNavState: function( state ) {
					if( state === 'toggle' ) {
						state = !this._nav_state;
					}

					this._nav_state = state;
					this._updateNav();
				},
				_updateNav: function() {
					console.log( 'updating off-canvas state to ' + ( this._nav_state === false ? 'invisible' : 'visible') );

					$( this.element).removeClass( 'off-canvas-nav--visible' );
					$( this.element ).removeClass( 'off-canvas-nav--invisible' );

					if( this._nav_state === false ) {
						$( this.element ).addClass( 'off-canvas-nav--invisible' );
						$( '#off-canvas-nav__overlay' ).hide();
					} else {
						$( this.element ).addClass( 'off-canvas-nav--visible' );
						$( '#off-canvas-nav__overlay' ).show( );
					}
				},
				_injectOverlay: function() {
					console.log( this.settings.canvas_selector );
					$( this.settings.canvas_selector ).append( '<div id="off-canvas-nav__overlay" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; display: none;"></div>' );
				}
		});

		// A really lightweight plugin wrapper around the constructor,
		// preventing against multiple instantiations
		$.fn[ pluginName ] = function ( options ) {
				return this.each(function() {
						if ( !$.data( this, "plugin_" + pluginName ) ) {
								$.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
						}
				});
		};

})( jQuery, window, document );
