/**
 * Masthead carousel
 */
;jQuery(function($) {
  var $masthead = $('#site-header__masthead');
  var index = 0;
  var $active_item = $($masthead.children()[0]);
  var tick = 0;
  var delay = 7; // change every x seconds

  initialise();

  function initialise() {
    $masthead.children().not(':first-of-type').hide();
    setInterval(progress, 6000);
  }

  function progress() {

    $active_item.fadeOut(2000);

    let max = $masthead.children().length;
    index = (index + 1) % max;

    $active_item = $( $masthead.children()[index] );

    $active_item.fadeIn(2000);

  }
});
