(function() {
  jQuery(function($) {

    // sticky header
    $(window).scroll(function() {

      var sticky_elements = [
        'site-header__outer-contain--bottom',
        'bottom-secondary-content__logo'
      ];

      if(window.scrollY > 132) {
        addStickyClasses();
      } else {
        removeStickyClasses();
      }

      function addStickyClasses() {
        sticky_elements.forEach(function( element ) {
          $('#' + element).addClass( element + '--sticky' );
        });
      }

      function removeStickyClasses() {
        sticky_elements.forEach(function( element ) {
          $('#' + element).removeClass( element + '--sticky' );
        });
      }

    });

    // off-canvas nav init
    $('#off-canvas-nav').c7offcanvas()
  });

  // masthead hover effects
  $('.site-header__masthead-image').hover( function() {
    $('.site-header__masthead-button-hider')
      .addClass('site-header__masthead-button-hider--visible')
      .removeClass('site-header__masthead-button-hider--hidden');
  }, function() {
    $('.site-header__masthead-button-hider')
      .addClass('site-header__masthead-button-hider--hidden')
      .removeClass('site-header__masthead-button-hider--visible');
  });

  function resizeHeader() {
    if($('.site-header__masthead--no-images').length) {
      var min_height = $('.site-header__sticky-wrap').height();
      $('.site-header__masthead--no-images').height(min_height);
    }
    console.log('resizing header');
  }

  resizeHeader();
  $(window).on('resize', resizeHeader);

  // top search bar
  $('#top-search-container__form').submit(function(e) {
    if( $(this).find('#top-search-container__search').val().trim() == "" ) {
      e.preventDefault();
      $(this).parents('#top-search-container').toggleClass('top-search-container--active');
      if($(this).parents('#top-search-container').hasClass('top-search-container--active')) {
        $('#top-search-container__search').focus();
      }
      return;
    }
  });

  $('#responsive-toolbar__button--search').click(function() {
    var query = prompt('Enter your search term');

    if(query) {
      window.location = '?s=' + query;
    }
  });

  $('#top-search-container__search:empty').blur(function() {
    $(this).parents('#top-search-container').removeClass('top-search-container--active');
  });

})();
