/***
* Basket module
*/
;jQuery(function($) {
  var $popup;
  var $open_button;
  var $close_button;
  var $form;
  var form_url;

  bindElements();

  $(window).scroll( updatePopupPosition );
  $(document).on( 'click', 'body *', handleBodyClick );
  $(document).on( 'click', 'a[href="#open-cart-popup"]', showPopup );

  function bindElements() {
    $popup = $('#basket-popup');
    $close_button = $('#basket-popup__close-button');
    $close_button.unbind().click(hidePopup);
    $form = $('#basket-popup__form');
    form_url = $form.attr('action');
    $form.unbind().submit(handleSubmit);

    if ( $('.body--woocommerce-checkout').length ) {
      $form.find('.basket-popup__contents-quantity-input').attr('disabled', 'disabled');
      $form.find('.basket-popup__contents-delete-button').remove();
    } else {
      $form.find('.basket-popup__contents-quantity-input').change(handleQuantityChange);
      $form.find('.basket-popup__contents-delete-button').click(handleDeleteClick);
    }
  }

  function showPopup(e) {
    // console.log('showing popup');
    e.preventDefault();
    reloadForm();
    $popup.addClass('basket-popup--visible');
  }

  function hidePopup(e) {
    if( e ) {
      e.preventDefault();
    }
    $popup.removeClass('basket-popup--visible');
  }

  function updatePopupPosition() {

    if (window.innerWidth < 992) {
      return;
    }

    var new_pos;
    if( window.scrollY > 134 ) {
      new_pos = 0;
    } else {
      new_pos = -78;
    }
    $popup.css( { top: new_pos } );

  }

  function handleBodyClick(e) {
    e.stopPropagation();

    // exclusions
    if(    $( this ).parents( '#basket-popup' ).length
        || $( this ).is( '#basket-popup' )
        || $( this ).is( '#top-interact-contain__btn--basket' )) {
    } else {
      hidePopup();
    }

  }

  // always submit by ajax
  function handleSubmit(e) {
    e.preventDefault();
  }

  // deleting items on the cart
  function handleDeleteClick(e) {
    e.preventDefault();

    var $this = $(this);

    disableForm();
    $.post( $(this).attr('href'), function() {
      handleDeletion.apply( $this );
    } );
  }

  function handleDeletion() {
    var $parent_li = $(this).parents('li.basket-popup__contents-item');
    console.log($parent_li);

    $parent_li.slideUp(function() {
      $parent_li.remove();
      reloadForm();
    })
  }

  // update the cart when quantities change
  function handleQuantityChange(e) {
    var current = + $(this).val();
    var max = + $(this).attr('max');

    if( current > max ) {
      $(this).val( max );
      alert( 'There are only ' + $(this).attr('max') + ' of this item in stock.' );
    } else if ( current < 0 ) {
      $(this).val(0);
    }

    submitForm( true );
  }

  function submitForm( force_reload ) {
    var data = $form.serialize();
    $.post( form_url, data, successHandler );

    disableForm();
    function successHandler( data ) {
      enableForm();

      if( force_reload == true ) {
        reloadForm();
      }
    }
  }

  // enable / disable editing of the form
  function disableForm() {
    $form.find('*').attr( 'disabled', 'disabled' );
    $form.addClass( 'basket-popup__form--loading' );
  }

  function enableForm() {
    $form.find('*').removeAttr( 'disabled' );
    $form.removeClass( 'basket-popup__form--loading' );
  }

  function reloadForm() {
    $.get( toa_utils.endpoint, { 'action': 'basket_html' } ).done(function( html ) {
      $form.parents( '#basket-popup' )
      .empty()
      .append( html );

      bindElements();
    });
  }
});
